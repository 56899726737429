const routes = [
  {
      path: '/',
      component: () => import('@/pages/pay/pay'),   //支付界面
      meta: { title: '向商家付款' }
  },
  {
      path: '/pay',
      component: () => import('@/pages/pay/pay'),   //支付界面
      meta: { title: '向商家付款' }
  },
  {
      path: '/paystatus',
      component: () => import('@/pages/paystatus/paystatus'),   //支付成功/支付失败
      meta: { title: '天美支付扫码支付' }
  },
  {
      path: '/zfbAuth',
      component: () => import('@/pages/zfbAuth/zfbAuth'),   //支付成功/支付失败
      meta: { title: '支付宝授权' }
  },
  {
      path: '/unableplat',
      component: () => import('@/pages/unableplat/unableplat'),  //不支持平台
      meta: { title: '天美支付扫码支付' }
  },
  {
      path: '*',
      component: () => import('@/pages/page404/index'),  //404页面
      meta: { title: '天美支付扫码支付' }
  },
  {
      path: '/404',
      component: () => import('@/pages/page404/index'),  //404页面
      meta: { title: '天美支付扫码支付' }
  }
]

export default routes